html, body {
  margin: 0px;
  height: 100%;
}

@media screen and (max-width: 600px) {
.boxes {
  font-size: 1.25rem;
  padding: 2rem; 
  background-color: #1A1A1A;
  color: white;
  text-align: center;
  width: 100%;
  margin-bottom: 100px;
}
}

@media screen and (min-width: 600px) {
  .boxes {
    font-size: 1.25rem;
    padding: 2rem; 
    background-color: #1A1A1A;
    color: white;
    text-align: center;
    width: 100%;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
}

.imageBox {
  font-size: 2.5rem;
  padding-top: 2rem; 
  padding-bottom: 0.1rem; 
  background-color: #1A1A1A;
  color: white;
  text-align: center;
  width: 100%;
  margin-bottom: 100px;
  }

.title {
  font-size: 2.5rem;
}

.header {
  font-size: 1.75rem;
}

.icon {
font-size: 7.5rem;
}

.small-icon {
  font-size: 3rem;
  }

.link {
  color: white;
}

.link :hover,  .link :active, .link :visited{
  color: #B0D39B;
  background-color: transparent;
}

.socials {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  background-color: #B0D39B !important;
  border-color: #B0D39B !important;
  color: #1A1A1A !important;
}

.background {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url(./images/Background.jpg);
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;
}

@media screen and (max-width: 600px) {
  .foreground {
    padding-top: 2%;
    max-width: 100%;
  }
}

@media screen and (min-width: 600px) {
.foreground {
  padding-top: 2%;
  max-height: 75%;
  max-width: 50%;
}
}

.slogan {
  text-align: center;
  font-size: 2rem;
  color: white;
  padding-bottom: 5%;
  font-family: serif;
}

  .navbar-custom {
    font-size: x-large;
    margin: auto;
    color: #1A1A1A;
    background-color: #B0D39B;
}

  .profile {
    max-width: 75%;
    max-height: 75%;
    border-radius: 100%;
  }


.content {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

section, div {
  scroll-margin-top: 80px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.images {
  max-width: 100%;
}

.link {
  font-size: 1rem;
}

.navbar {
  margin: auto;
}

